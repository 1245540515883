import React from "react";
import "./styles.css"
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import * as axios from "axios";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

export default class Statistics extends React.Component {
  state = {
    totals: [],
    comision: [],
    labels: [],
    orders: [],
  }

  constructor() {
    super();
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );
    this.state = {
      loading: true,
      series: [],
      ordersSeries: [],
      totals: [],
      width: 0,
      height: 0,
      options: {
        chart: {
          id: 'totals',
          type: 'line',
          height: 350,
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: new Date('18 Aug 2020').getTime(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        },
        colors: ['#EB9436', '#2B9BEC', '#23B80B', '#CD0B51', '#BA34EA', '#6B5F4C'],
      },
      ordersOptions: {
        chart: {
          id: 'orders',
          type: 'line',
          height: 350,
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: new Date('18 Aug 2020').getTime(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: 'ddd, dd.MMM.yyyy'
          }
        },
        colors: ['#EB9436', '#2B9BEC', '#23B80B', '#CD0B51', '#BA34EA', '#6B5F4C'],
      },
      selection: 'one_year',
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.getTotals();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  groupByTimePeriod = function (obj, timestamp, period) {
    var objPeriod = {};
    var oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    for (var i = 0; i < obj.length; i++) {
      var d = new Date(obj[i][timestamp] * 1000);
      if (period == 'day') {
        d = Math.floor(d.getTime() / oneDay);
      } else if (period == 'week') {
        d = Math.floor(d.getTime() / (oneDay * 7));
      } else if (period == 'month') {
        d = (d.getFullYear() - 1970) * 12 + d.getMonth();
      } else if (period == 'year') {
        d = d.getFullYear();
      } else {
        console.log('groupByTimePeriod: You have to set a period! day | week | month | year');
      }
      // define object key
      objPeriod[d] = objPeriod[d] || [];
      objPeriod[d].push(obj[i]);
    }
    return objPeriod;
  };

  getTotals = async () => {
    this.setState({loading: true});
    const token = localStorage.getItem('access_token');
    const width = this.state.width || window.innerWidth;
    const totalsUrl = width > 500 ? 'https://api.appetito.ro/v1/admin/totals-by-date' : 'https://api.appetito.ro/v1/admin/totals-by-date/last-two-weeks';
    const response = await axios.default.get(totalsUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const labels = [];
    const comision = [];
    const orders = [];
    const totals = [];
    const months = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec']
    const byMonth = this.groupByTimePeriod(response.data, 'timestamp', 'month')
    for (const month in byMonth) {
      let total = 0
      let nrComenzi = 0
      for (const obj in byMonth[month]) {
        total += parseFloat(byMonth[month][obj].total)
        nrComenzi += byMonth[month][obj].count
      }
      const date = new Date(byMonth[month][0].timestamp * 1000);
      if (date.getFullYear() > 2021) {
        labels.push(months[date.getMonth()] + ", " + date.getFullYear())
        comision.push(total.toFixed(2))
        orders.push(nrComenzi)
      }
    }
    for (let total of response.data) {
      totals.push({
        date: total.timestamp * 1000,
        total: parseFloat(total.total).toFixed(2),
        count: total.count
      })
    }
    this.setState({
      loading: false,
      labels,
      comision,
      orders,
      totals
    })
  }

  parseDate(timestamp) {
    const date = new Date(timestamp);
    const days = ['Duminica','Luni','Marti','Miercuri','Joi','Vineri','Sambata'];
    return `${days[date.getDay()]}, ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>
      )
    }
    if (this.state.width <= 500) {
      return (
        <div>
          <div className={"container"} style={{marginTop: 60}}>
            <table style={{borderSpacing: 0}}>
              <tbody>
              <tr>
                <th className={"row first header"}>Data</th>
                <th className={"row header comision"}>Comision</th>
                <th className={"row header"}>Nr.</th>
              </tr>
              {this.state.totals.map((totalRow, i) => {
                console.log(totalRow)
                return (
                  <tr>
                    <td className={"row first"}>{this.parseDate(totalRow.date)}</td>
                    <td className={"row"}>{totalRow.total} lei</td>
                    <td className={"row"}>{totalRow.count}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
    return (
      <div className="container">
        <Bar options={this.state.options} data={{
          labels: this.state.labels,
          datasets: [
            {
              label: 'Comision',
              data: this.state.comision,
              backgroundColor: '#EA9438',
            },
            {
              label: 'Nr. Comenzi',
              data: this.state.orders,
              backgroundColor: '#223B75',
            }
          ]
        }}  type={"bar"}/>
      </div>
    )
  }
}